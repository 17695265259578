<template>
    <vs-row direction="column">
    
    <vs-dialog v-model="couponDialog" title="Coupon" @closed="couponDialog = false">
        <template #header>
            <h4 style="margin-bottom: 10px;">
                Crea <b>coupon</b>
            </h4>

        </template>

        <vs-row v-if="coupon" style="gap: 30px;">
            <vs-col>
                <vs-input v-model="coupon.couponName" label="Nome" block/>
            </vs-col>
            <vs-col>
                <vs-input v-model="coupon.discountPercent" label="Percentuale" block/>
            </vs-col>
            <vs-col>
                <vs-input v-model="coupon.maxUses" label="Utilizzi" block/>
            </vs-col>
            <!--<vs-col>
                <vs-select v-model="coupon.productIds" label="Prodotti" multiple block>
                        
                    <vs-option v-for="p in products" :key="p.id" :value="p.id" :label="p.name">
                        {{ p.name }}
                    </vs-option>
                </vs-select>
            </vs-col>-->
        </vs-row>
        <vs-row>
            <vs-button @click="couponDialog = false"  block transparent>Annulla</vs-button>
            <vs-button @click="saveCoupon" block>Salva</vs-button>
        </vs-row>
    </vs-dialog>

    <vs-row justify="flex-end">
        <vs-button @click="newCoupon">Crea</vs-button>
    </vs-row>
    <vs-row>
        <vs-table striped style="width: 100%;" v-model="font">
            <template #thead>
                <vs-tr>
                    <vs-th style="width: 5%;">
                        Id
                    </vs-th>
                    <vs-th style="width: 30%;">
                        Codice
                    </vs-th>
                    <vs-th style="width: 30%;">
                        Percentuale
                    </vs-th>
                    <vs-th>
                        Utilizzi
                    </vs-th>
                    <vs-th>
                        Azioni
                    </vs-th>
                </vs-tr>
            </template>
            <template #tbody>
                <vs-tr :key="i" v-for="(tr, i) in coupons" >
                    <vs-td>
                        {{ tr.id }}
                    </vs-td>
                    <vs-td>
                        {{ tr.name }}
                    </vs-td>

                    <vs-td>
                        {{ tr.percent_off }}%
                    </vs-td>

                    <vs-td>
                        {{tr.times_redeemed}} / {{ tr.max_redemptions }}
                    </vs-td>

                    
                    <vs-td>
                        <vs-button @click="deleteCoupon(tr.id)">Elimina</vs-button>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
    </vs-row>
</vs-row>
</template>

<script>
import {
    apiCall,
} from '../../client';
export default {
    data() {
        return {
            coupons: [],
            coupon: null,

            products: [],

            couponDialog: false
        }
    },
    methods: {
        async getCoupons() {
            var result = await apiCall('GET', '/Coupon/all');
            if (result.status == 200) {
                this.coupons = result.data;
            }
        },

        async getStripeProducts() {
            var result = await apiCall('GET', '/Coupon/Products'); // post instead of get because of conflict in user endpoint
            if (result.status == 200) {
                this.products = result.data;
            }
        },

        async saveCoupon() {
            var result = await apiCall('POST', '/Coupon', this.coupon);
            if (result.status == 200) {
                this.getCoupons();
                this.couponDialog = false;
            }
        },

        newCoupon(){
            this.coupon = {
                couponName: '',
                productIds: [],
                maxUses: 10,
                discountPercent: 10,
            }
        },

        async deleteCoupon(id){
            var result = await apiCall('DELETE', '/Coupon/' + id);
            if (result.status == 200) {
                this.$vs.notification({
                    title: 'Coupon eliminato',
                    text: 'Il coupon è stato eliminato con successo',
                    color: 'success'
                });
                this.getCoupons();
            }
        }
    },

    watch: {
        coupon: {
            handler: function (val) {
                this.couponDialog = true;
            },
            deep: true
        }
    },

    mounted() {
        this.getCoupons();
        this.getStripeProducts();
    }
}
</script>